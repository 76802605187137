<template>
  <!--
    The view for the Contacts
  -->
  <Portlet
    :title="$t('menu.contacts')"
    icon="address-card"
    class="contacts"
  >
    <template slot="buttons">
      <button
        v-if="!isCall" 
        class="btn btn-sm btn-primary float-right mt-2 mb-2" 
        @click="showSidebarAdd"
      >
        <font-awesome-icon
          class="mr-1"
          icon="plus"
        />
        <span>
          {{ $t('add') }}
        </span>
      </button>
      <div class="clearfix" />
    </template>
    <span class="phoneCall">{{ callTitle }}</span>
    <ContactsList
      ref="contactsList" 
      @showCall="showCall"
      @reloadAuditLogs="reloadAuditLogs"
    /> 
    <DatabaseAuditLogList
      v-if="authenticationHasRole('scope_staff')"
      ref="databaseAuditLogList"
      :is-contact="true"
      @reload="reloadContactsList"
    />
  </Portlet>
</template>

<script>
import { authenticationMixin } from '@/mixins/authenticationMixin';

export default {
  name: "Contacts",
  components: {
    ContactsList: () => import('@/components/Contacts/ContactsList.vue'),
    DatabaseAuditLogList: () => import('@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue')
  },
  mixins: [
    authenticationMixin
  ],
  metaInfo () {
    return {
      title: this.$t('menu.contacts')
    }
  },
  data () {
    return {
      callTitle: null,
      isCall: false
    };
  },
  methods: {
    showSidebarAdd () {
      this.$refs.contactsList.showSidebarAdd();
    },
    reloadAuditLogs () {
      if (this.authenticationHasRole('scope_staff')) {
        this.$refs.databaseAuditLogList.reloadAuditLogs();
      }
    },
    reloadContactsList () {
      this.$refs.contactsList.reloadContacts();
    },
    showCall (val) {
      this.isCall = true
      this.callTitle = val;
    }
  }
}
</script>
<style scoped>
 .phoneCall {
   color: red;
   font-weight: bold;
   font-size: 1.3rem;
 }
</style>